import React from "react";
import { Container } from "react-bootstrap";
import "../SCSS/_thankyou.scss";
import { Link } from "react-router-dom";

function ThankyouPage() {
  return (
    <Container className="thankyou-container">
      <h1>Thankyou for contacting Us!</h1>
      <p>Our team will reach you out soon</p>
      <Link to="/">Return to Homepage</Link>
    </Container>
  );
}

export default ThankyouPage;
