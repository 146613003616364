import React from "react";
import { Container } from "react-bootstrap";
import "../SCSS/_about.scss";

function About() {
  return (
    <Container className="about-container">
      <Container className="seprator-left"></Container>
      <Container className="profile-container">
        <h1>Profile</h1>
        <p>
          Infinitypower Engineering is a leading provider of premium Diesel
          generators, established in year 2023 with a vision to provide reliable
          and efficient power solutions to customers across the world. With
          tremendous knowledge as well as international experience across
          countries like Europe, Singapore, China, Russia, Africa, and Iran in
          the power industry, Our Establishment recognized the need for
          high-quality generators that could meet the diverse power needs of
          homes and businesses alike.
          <br />
          <br />
          Since its inception, Infinitypower has become a trusted name in the
          power industry, known for its commitment to quality, innovation, and
          exceptional customer service. Our team of experts is dedicated to
          providing you with top-quality generators, customized solutions, and
          unparalleled support to ensure that your power needs are always met.
          <br />
          <br />
          At Infinitypower, we are passionate about helping customers achieve
          their power goals, whether that means providing a backup power supply
          for your home or delivering a reliable energy solution for your
          business. We are committed to excellence in everything we do, and we
          strive to exceed your expectations at every turn.
          <br />
          <br />
          Choose Infinitypower and experience the power of reliability,
          expertise, and exceptional service today!
        </p>
      </Container>
      <Container className="seprator-right"></Container>
      <Container className="product-and-services-container">
        <h1>Product And Services</h1>
        <p>
          We are committed to deliver the best and economical services without
          compromising on the reliability of performance.
        </p>

        <span>Some of them are follows:</span>

        <ul>
          <li>Sales</li>
          <li>Installation</li>
          <li>Spare Parts</li>
          <li>Service and Maintance Contract (AMC)</li>
          <li>Alternator Installation, Service and Maintance</li>
          <li>Electrical Panel Sales, Installation, Service and Maintance</li>
        </ul>
      </Container>
    </Container>
  );
}

export default About;
