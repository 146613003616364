import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";

import ThankyouPage from "./Components/ThankyouPage";
import About from "./Components/About";
import ContactPage from "./Components/ContactPage";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
