import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heroimg from "../Assets/PNG/Heroimg.png";
import "../SCSS/_home.scss";
import { Link } from "react-router-dom";
import productimg1 from "../Assets/PNG/img1.png";
import productimg2 from "../Assets/PNG/img2.png";
import productimg3 from "../Assets/PNG/img3.png";

import productimg6 from "../Assets/PNG/img7.png";
import productimg7 from "../Assets/PNG/img8.png";
import productimg8 from "../Assets/PNG/img9.png";
import productimg9 from "../Assets/PNG/img10.jpg";
import productimg10 from "../Assets/PNG/img11.jpg";
import productimg11 from "../Assets/PNG/img12.jpg";

import productimg13 from "../Assets/PNG/img14.jpg";
import productimg14 from "../Assets/PNG/img15.jpg";
import productimg15 from "../Assets/PNG/img16.jpg";
import productimg16 from "../Assets/Images/g14.jpg";
import productimg17 from "../Assets/Images/g15.jpg";
import productimg18 from "../Assets/Images/g16.jpg";
import serviceimg from "../Assets/PNG/img4.jpg";
import trustimg from "../Assets/PNG/trust.png";
import callimg from "../Assets/PNG/call.png";
import moneyimg from "../Assets/PNG/money.png";
import teamimg from "../Assets/PNG/team.png";
import Contact from "./Contact";
import Logo from "../Assets/PNG/Main Logo.png";

import perfectlogo from "../Assets/PNG/perfectLogo.png";
import cpimg1 from "../Assets/PNG/cpimg1.png";
import cpimg2 from "../Assets/PNG/cpimg2.png";
import cpimg3 from "../Assets/PNG/cpimg3.png";
import cpimg4 from "../Assets/PNG/cpimg4.png";

function Home() {
  const data = [
    { image: productimg1, title: "DG Set 20-2000KVA" },
    { image: productimg2, title: "Sound Proof Acoustic Enclosure" },
    { image: productimg9, title: "DG set synchronisation panel" },
    { image: productimg11, title: "Diesel Generator" },
    { image: productimg10, title: "DG Set AMF Panel" },
    { image: productimg6, title: "Diesel Generator Spare Parts" },
    { image: productimg7, title: "Diesel Generator 20KVA-2000KVA" },
    { image: productimg8, title: "Diesel Generator 20KVA-2000KVA" },
    { image: productimg3, title: "Recon DG Sets" },
    { image: productimg13, title: "AMF Controller" },
    { image: productimg14, title: "DG Set Installation Work" },
    { image: productimg15, title: "DG Set Installation Work" },
    { image: productimg16, title: "Installation Work " },
    { image: productimg17, title: "DG set Alternator" },
    { image: productimg18, title: "Electrical Power Transformer" },
  ];

  return (
    <Container className="Home">
      <Row className="landing-page">
        <Col className="intro-container" md="6">
          <h1>
            Your One-Stop-Shop For Premium Diesel Generators Sales And Services.
          </h1>
          <p>
            Welcome to Infinitypower Engineering, your trusted source for
            premium diesel generators. Our company is dedicated to providing
            reliable, efficient, and powerful generators to ensure that you
            always have the energy you need, when you need it. With tremendous
            knowledge and experience in the industry, our team of experts is
            committed to delivering top-quality products and unparalleled
            customer service. Whether you're looking for a backup power solution
            for your home or need a reliable source of energy for your business,
            Infinitypower Engineering has got you covered. Browse our selection
            today and experience the power of Infinitypower!
          </p>

          <Link to="contact">Contact Us</Link>
        </Col>

        <Col md="6" className="hero-img-container">
          <img src={Heroimg} alt="img" />
        </Col>
      </Row>

      <Container className="seprator-left"></Container>

      <Container className="products-container">
        <h1>Our Products</h1>
        <p>
          Infinitypower product line offers you an exciting palette of
          build-to-order option for your power generator sets. Our generators
          are designed to provide reliable and efficient energy, no matter what
          your power needs may be. Equipped with advanced technology and
          high-quality components, our generators are built to last and deliver
          exceptional performance. Whether you need a backup power source for
          your home or a reliable energy solution for your business, the
          Infinitypower generator has got you covered. With easy installation
          and user-friendly operation, our generators make it easy to stay
          powered up, even during the toughest of times. Choose Infinitypower
          and experience the power of reliability today!
        </p>

        <Row className="product-list">
          {data.map((e) => {
            return (
              <Col md="6" className="product-list-items">
                <img src={e.image} alt="img" />
                <h3>{e.title}</h3>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className="seprator-right"></Container>
      <Container className="services-container">
        <h1>Our Services</h1>
        <Row>
          <Col md="6" className="service-img-container">
            <img src={serviceimg} alt="img" />
          </Col>
          <Col md="6" className="service-txt-container">
            <p>
              At Infinitypower, we don't just provide top-quality electric
              generators - we also offer a range of exceptional services to
              support your power needs. From installation and maintenance to
              repair and replacement, our team of experts is here to ensure that
              your generator is always running smoothly. We understand that
              power outages can be a major inconvenience, which is why we offer
              emergency repair services to get you back up and running as
              quickly as possible. Our technicians are highly trained and
              experienced, and we use only genuine parts and components to
              ensure the longevity and reliability of your generator. With
              Infinitypower, you can trust that you're getting not just a
              product, but also top-quality services and support. We Provide :
            </p>
            <ul>
              <li>+ Diesel Generator Sales</li>
              <li>+ Installation</li>
              <li>+ Service And Maintance Contract (AMC)</li>
              <li>+ Alternator Installation, Service and Maintance</li>
              <li>
                + Electrical Panel Sales, Installation, Service and Maintance
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="seprator-left"></Container>

      <Container className="why-us-container">
        <h1>Why Choose Us</h1>
        <p>
          Our experience , contacts , relationships built on huge and watertight
          channels of communication with our manufacturers and suppliers enables
          us to serve you and many others with the best engineering and
          solutions we can offer.
        </p>

        <Row className="value-container">
          <Col md="6" className="value-items">
            <img src={trustimg} alt="img" />
            <div className="value-text-container">
              <h2>Customer Satisfaction</h2>
              <p>
                We take pride in our ideal products and ever impeccable service
                which keeps adding to our number of satisfied customers
                everyday.
              </p>
            </div>
          </Col>
          <Col md="6" className="value-items">
            <img src={callimg} alt="img" />
            <div className="value-text-container">
              <h2>Best support</h2>
              <p>
                Exceeding customer expectations by always being the first to
                market the best products. Partnering with our customers to make
                sure they succeed.
              </p>
            </div>
          </Col>
          <Col md="6" className="value-items">
            <img src={moneyimg} alt="img" />
            <div className="value-text-container">
              <h2>Afforadable Price</h2>
              <p>
                We have team of highly skilled professionals who stringently
                inspect all goods and services provided by us in the most
                affordable price possible.
              </p>
            </div>
          </Col>
          <Col md="6" className="value-items">
            <img src={teamimg} alt="img" />
            <div className="value-text-container">
              <h2>Expert team members</h2>
              <p>
                Our team of experts is committed to delivering top-quality
                products and unparalleled customer service.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="seprator-right"></Container>

      <Container className="chanel-partner-container">
        <h1>We Are Authorized Channel Partners</h1>
        <p>
          Infinity Power Engineering is Authorised Channel Partner / Sales
          Dealer of Perfect House Pvt Ltd, we supply <strong>5KVA</strong> to{" "}
          <strong>2500KVA</strong> Eicher- TMTL Brand Generator Set and Baudouin
          Brand Generator Sets. Please contact us for requirement.
        </p>
        <Container>
          <Container>
            <img src={perfectlogo} alt="img" />
          </Container>
          <Row>
            <Col>
              <img src={cpimg1} alt="img" />
            </Col>

            <Col>
              <img src={cpimg2} alt="img" />
            </Col>
            <Col>
              <img src={cpimg3} alt="img" />
            </Col>
            <Col>
              <img src={cpimg4} alt="img" />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="seprator-right"></Container>

      <Contact />

      <Container className="seprator-left"></Container>

      <Container className="end-logo">
        <img src={Logo} alt="img" />
      </Container>
      <Container className="seprator-right"></Container>
    </Container>
  );
}

export default Home;
