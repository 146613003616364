import React from "react";
import "../SCSS/_gallery.scss";
import g1 from "../Assets/Images/g1.jpg";
import g2 from "../Assets/Images/g2.jpg";
import g3 from "../Assets/Images/g3.jpg";
import g4 from "../Assets/Images/g4.jpg";
import g5 from "../Assets/Images/g5.jpg";
import g6 from "../Assets/Images/g6.jpg";
import g7 from "../Assets/Images/g7.jpg";
import g8 from "../Assets/Images/g8.jpg";
import g9 from "../Assets/Images/g9.jpg";
import g10 from "../Assets/Images/g10.jpg";
import g11 from "../Assets/Images/g11.jpg";
import g12 from "../Assets/Images/g12.jpg";
import g13 from "../Assets/Images/g13.jpg";
import g14 from "../Assets/Images/g14.jpg";
import g15 from "../Assets/Images/g15.jpg";
import g16 from "../Assets/Images/g16.jpg";
import { Col, Container, Row } from "react-bootstrap";

function Gallery() {
  const images = [
    { image: g1 },
    { image: g2 },
    { image: g3 },
    { image: g4 },
    { image: g5 },
    { image: g6 },
    { image: g7 },
    { image: g8 },
    { image: g9 },
    { image: g10 },
    { image: g11 },
    { image: g12 },
    { image: g13 },
    { image: g14 },
    { image: g15 },
    { image: g16 },
  ];
  return (
    <Container className="gallery-container">
      <Row className="gallery-row">
        {images.map((e) => {
          return (
            <Col className="gallery-items">
              <img src={e.image} alt="img" />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Gallery;
